import { Routes } from 'react-router-dom';

import { routeConfigRoutes } from './route-config';
import { asyncStatusRoutes } from './async-status';

type OperationsRoutesProps = {
  which: 'route_config';
};

type OperationsAsyncStatusProps = {
  which: 'async_status';
};

const routesMap = {
  route_config: routeConfigRoutes,
  async_status: asyncStatusRoutes,
};

export const OperationsRoutes: React.FC<OperationsRoutesProps> = ({ which }) => {
  return <Routes>{routesMap[which]}</Routes>;
};

export const AsyncStatusRoutes: React.FC<OperationsAsyncStatusProps> = ({ which }) => {
  return <Routes>{routesMap[which]}</Routes>;
};
