export enum SENTRY_FEATURE {
  SHIFT = 'shift',
  DASHBOARD = 'dashboard',
  ORDER_WELL = 'order-well',
  SHIFT_BOARD = 'shift-board',
  BULK_DELETE = 'bulk-delete',
  LOADING_ORDER = 'loading-order',
  TRANSFER_ORDER = 'transfer-order',
  DELIVERY_ORDER = 'delivery-order',
  RECURRING_ORDER = 'recurring-order',
  LINK_ORDER_SHIFT = 'link-order-shift',
  BULK_EDIT_ASSIGN = 'bulk-edit-assign',
  CREATE_ORDER_BETA = 'create-order-beta',
  LOAD_ORDER_DETAILS = 'load-order-details',
  PUBLIC_ORDER_DETAIL = 'public-order-detail',
  BULK_PLANT_INVENTORY = 'bulk-plant-inventory',
  DELIVERY_ORDER_DETAIL = 'delivery-order-detail',
}
