import { Navigate, Route } from 'react-router-dom';

import { lazyImport } from 'utils/lazyImport';

const { RouteConfig } = lazyImport(() => import('./RouteConfig'), 'RouteConfig');

export const routeConfigRoutes = (
  <Route path="/">
    <Route index element={<RouteConfig />} />
    <Route path="*" element={<Navigate to=".." replace />} />
  </Route>
);
