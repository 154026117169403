import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { deleteProperty, setProperty } from 'dot-prop';
import produce from 'immer';
import * as React from 'react';

import { getAuthToken, logout } from 'features/auth';
import { Driver, CustomerBranch } from 'generated/graphql';
import { COMMONS } from 'globalConstants';
import { toast } from 'lib/toast';

const { ERROR } = COMMONS;
const API_URL = process.env.REACT_APP_REMOTE_HOST + 'graphql';
const NETWORK_ERROR_TOAST_ID = 'network-error';

export async function fetchGraphQLDataMutation<T>(query: string): Promise<T> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Source: 'standaloneapp',
  };

  const token = getAuthToken();

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: query,
  });

  const data = await response.json();

  return data;
}

const httpLink = createHttpLink({
  uri: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Source: 'standaloneapp',
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getAuthToken();

  operation.setContext(
    produce((context: Record<string, unknown>) => {
      if (token) {
        setProperty(context, 'headers.Authorization', `Bearer ${token}`);
      } else {
        deleteProperty(context, 'headers.Authorization');
      }
    })
  );

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (!networkError || !('result' in networkError) || !networkError.result?.data?.error_code) {
    // Network error(500)
    // TODO: Send log to sentry for 500
    return;
  }

  const showErrorAndLogout = (title: string, description: string) => {
    if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
      toast({
        id: NETWORK_ERROR_TOAST_ID,
        title,
        description,
        status: ERROR,
        position: 'top-right',
        isClosable: true,
      });
    }

    if (location.pathname !== '/auth/login') {
      logout(location.href.replace(location.origin, ''));
    }
  };

  switch (networkError.result.data.error_code) {
    case 1014:
      showErrorAndLogout(
        'Expired credentials',
        'Your authentication token has expired. Please log in again.'
      );
      break;
    case 1003:
      showErrorAndLogout(
        'Invalid credentials',
        'Your authentication token is invalid. Please log in again.'
      );
      break;
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        filteredDrivers: {
          read(existing) {
            return existing?.collection ? existing : {};
          },
          keyArgs: ['filter'],
          merge(existing, incoming, { args }) {
            const offset = args?.offset || 0;
            const hasIncColl = incoming?.collection.length ? incoming?.collection : [];
            const mergedColl = existing?.collection?.length
              ? [...existing.collection]?.splice(0)
              : [];
            hasIncColl?.forEach((indvCol: Driver, index: number) => {
              mergedColl[offset + index] = indvCol;
            });
            const mergedDriverList = { ...incoming, collection: mergedColl };
            return mergedDriverList;
          },
        },
        filteredCustomerBranches: {
          read(existing) {
            return existing?.collection ? existing : {};
          },
          keyArgs: ['filter'],
          merge(existing, incoming, { args }) {
            const offset = args?.offset || 0;
            const hasIncColl = incoming?.collection.length ? incoming?.collection : [];
            const mergedColl = existing?.collection?.length
              ? [...existing.collection]?.slice(0)
              : [];
            hasIncColl?.forEach((indvCol: CustomerBranch, index: number) => {
              mergedColl[offset + index] = indvCol;
            });
            const mergedHubList = { ...incoming, collection: mergedColl };
            return mergedHubList;
          },
        },
      },
    },
  },
});

persistCache({
  cache,
  storage: new LocalStorageWrapper(localStorage),
});

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

export const ApolloWrapper = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[] | null;
}) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
